import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './App.css';


const Pbi = () => {
	
	const [accessToken, setToken] = useState("");
	const [accessToken2, setToken2] = useState("");
	const [id, setId] = useState("");
	const [id2, setId2] = useState("");
	const [embedUrl, setUrl] = useState("");
	const [embedUrl2, setUrl2] = useState("");
	const [orgid, setOrgID] = useState("");
	
	useEffect(() => {  
		var embedReportId2 = '9dc861bb-f610-4360-aef5-6a21b6b2aaca';
		var embedReportId = '4a4b10a2-93a4-450d-b4fc-d034713ea902';
		var groupID2 = '6096dff8-8310-485c-9cdc-aa534039f786'; 	
		var groupID = '6096dff8-8310-485c-9cdc-aa534039f786'; 			
		var theembedUrl = 'https://app.powerbi.com/reportEmbed?reportId=' + embedReportId + '&groupId=' + groupID;
		var theembedUrl2 = 'https://app.powerbi.com/reportEmbed?reportId=' + embedReportId2 + '&groupId=' + groupID2;
		
		setId(embedReportId);		
		setUrl(theembedUrl);

		setId2(embedReportId2);		
		setUrl2(theembedUrl2);
				
		var org_cst_key = '';
		var pathname = window.location.pathname.replace("/","");
		//console.log(pathname);
		org_cst_key = pathname;
		
		var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", "uZtu9iISFC9VpKV3zzvEs1bb6LSAj3wW8EAGk1zI"); 
		var requestOptions = {method: 'GET', headers: headers, redirect: 'follow'};

		const fetchOrgID= async () => {
            try {
                const response = await fetch("https://api.apiacumen.com/api/nigp/roi/" + org_cst_key, requestOptions);
                const json = await response.json();
                console.log(json["orgid"]);
                setOrgID(json["orgid"]);
            } catch (error) {
                console.log("error", error);
            }
        };        
        fetchOrgID();

		var raw = JSON.stringify({
			"group_id": groupID,
			"report_id": embedReportId,
			"accesslevel": "view",
			"allowsaveas": "false"
		});	
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-api-key", "uZtu9iISFC9VpKV3zzvEs1bb6LSAj3wW8EAGk1zI"); 
        var requestOptions = {method: 'POST', headers: myHeaders, body: raw, redirect: 'follow'};
		
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.apiacumen.com/api/pbitoken", requestOptions);
                const json = await response.json();
                console.log(json.body);
                setToken(json.body);
            } catch (error) {
                console.log("error", error);
            }
        };        
        fetchData();

		var raw2 = JSON.stringify({
			"group_id": groupID2,
			"report_id": embedReportId2,
			"accesslevel": "view",
			"allowsaveas": "false"
		});	
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-api-key", "uZtu9iISFC9VpKV3zzvEs1bb6LSAj3wW8EAGk1zI"); 
        var requestOptions = {method: 'POST', headers: myHeaders, body: raw2, redirect: 'follow'};
		
        const fetchData2 = async () => {
            try {
                const response = await fetch("https://api.apiacumen.com/api/pbitoken", requestOptions);
                const json = await response.json();
                console.log(json.body);
                setToken2(json.body);
            } catch (error) {
                console.log("error", error);
            }
        };        
        fetchData2();

    }, []);

	if (orgid == '')
	{
		return (<div>&nbsp;</div>);
	}
	
	if (accessToken == '')
	{
		return (<div>&nbsp;</div>);
	}
	
	return (
		<div>			
			<div style={{height:'100vh', width:'100%'}}>	
				
				<PowerBIEmbed
					embedConfig = {{
					type: 'report',  
					pageName: 'ReportSection',
					id: id, 
					embedUrl: embedUrl,
					accessToken: accessToken,    // Keep as empty string, null or undefined
					tokenType: models.TokenType.Embed,
					filters: [{
						$schema: "http://powerbi.com/product/schema#basic",
						target: {
						
						table: "Primary Organization",
						column: "organization_id"
						},
						operator: "In",
						values: [orgid],
						displaySettings: {isHiddenInViewMode: true},
						requireSingleSelection: true
					}],					
					settings: {
						filterPaneEnabled: true,
						navContentPaneEnabled: false,						
						//background: 1,                  
						layoutType: models.LayoutType.Custom,
						panes: {
							filters: {expanded: false,visible: false},
							pageNavigation: {visible: false, position: 1}
							}
						}
					}}
					
					cssClassName={"Embed-container"}
				/>
				<PowerBIEmbed
					embedConfig = {{
					type: 'report',  
					pageName: 'ReportSection2',
					id: id2, 
					embedUrl: embedUrl2,
					accessToken: accessToken2,    // Keep as empty string, null or undefined
					tokenType: models.TokenType.Embed,
					filters: [{
						$schema: "http://powerbi.com/product/schema#basic",
						target: {
							table: "Organization",
						column: "organization_id"
						},
						operator: "In",
						values: [orgid],
						displaySettings: {isHiddenInViewMode: true},
						requireSingleSelection: true
					}],					
					settings: {
						filterPaneEnabled: true,
						navContentPaneEnabled: false,						
						//background: 1,                  
						layoutType: models.LayoutType.Custom,
						panes: {
							filters: {expanded: false,visible: false},
							pageNavigation: {visible: false, position: 1}
							}
						}
					}}
					
					cssClassName={"Embed-container"}
				/>			
			</div>
		</div>
	);
};
export default Pbi;
